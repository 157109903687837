import {
  usePaymentSessionForm,
  usePaymentSessionQuery,
} from './payment-session';

export const useTransactionData = () => {
  const { data: transitionData } = usePaymentSessionQuery();
  const { data: sessionFormData } = usePaymentSessionForm();
  const { created, amountAfterFee, transactionId, txId } =
    transitionData?.transaction || {};
  const { presetToken, userEmail } = sessionFormData || {};

  return {
    created,
    userEmail,
    transactionId,
    amount: amountAfterFee,
    tokenCode: presetToken,
    transactionHash: txId,
  };
};
