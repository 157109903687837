import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import createLogger from 'debug';
import {
  TCurrency,
  TDepositInfoRequestParams,
  TNetworkType,
} from '@payler/payment-page-api-crypto';
import { useInvalidatePaymentSessionQuery } from '../hooks/payment-session';
import { useCreateGlobalTimerRef } from '@payler/payment-page-ui-shared';
import {
  usePaymentSessionPresetData,
  usePaymentSessionStatus,
} from '../hooks/payment-session';
import { PENDING_AWAIT_TIME_MS } from '../consts';
import { useAvailableTokens } from '../hooks/tokens';
import { MayBe } from '@payler/utils';

const log = createLogger('CryptoPaymentContextProvider');

type TScreen =
  | 'PaymentSelectionScreen'
  | 'DepositInfoScreen'
  | 'ProcessingTransaction'
  | 'PaymentCompleted';
type TSelectedToken = TDepositInfoRequestParams;

type TCryptoPaymentContext = {
  selectedToken: TSelectedToken | undefined;
  setSelectedToken: (selectedToken: TSelectedToken) => void;
  screen: TScreen | undefined;
  setScreen(screen: TScreen): void;
};

const CryptoPaymentContext = createContext<TCryptoPaymentContext>(
  {} as unknown as TCryptoPaymentContext
);

export const CryptoPaymentContextProvider: FCC = ({ children }) => {
  const [screen, setScreen] = useState<TScreen>();
  const [selectedToken, setSelectedToken] = useState<
    TSelectedToken | undefined
  >();
  const timerRef = useCreateGlobalTimerRef('transactionTimer');
  const { tokenCode, network } = usePaymentSessionPresetData();
  const paymentSessionStatus = usePaymentSessionStatus();
  const invalidateSessionQuery = useInvalidatePaymentSessionQuery();
  const availableTokens = useAvailableTokens();

  const handleSetScreen = (screen: TScreen) => {
    log('set screen %s', screen);
    setScreen(screen);
  };

  const showDepositInfoScreen = useCallback(
    (network: MayBe<TNetworkType>, currency: MayBe<TCurrency>) => {
      if (currency && network) {
        handleSetScreen('DepositInfoScreen');
        setSelectedToken({ TokenCode: currency, NetworkType: network });
      } else {
        throw new Error('Network type and token code is required');
      }
    },
    []
  );

  useEffect(() => {
    if (paymentSessionStatus !== 'completed') {
      return;
    }

    handleSetScreen('PaymentCompleted');
  }, [paymentSessionStatus]);

  useEffect(() => {
    if (paymentSessionStatus !== 'created' || !!screen) {
      return;
    }

    if (availableTokens?.data?.length === 1) {
      const { network, symbol } = availableTokens.data[0] || {};
      showDepositInfoScreen(network, symbol);
    } else if (network && tokenCode) {
      showDepositInfoScreen(network, tokenCode);
    } else {
      handleSetScreen('PaymentSelectionScreen');
    }
  }, [
    availableTokens.data,
    network,
    paymentSessionStatus,
    screen,
    showDepositInfoScreen,
    tokenCode,
  ]);

  useEffect(() => {
    if (
      paymentSessionStatus !== 'markedPaid' &&
      paymentSessionStatus !== 'pending'
    ) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }

      return;
    }

    if (screen !== 'ProcessingTransaction') {
      handleSetScreen('ProcessingTransaction');
      if (!timerRef.current) {
        timerRef.current = setInterval(invalidateSessionQuery, 2000);
        setTimeout(() => {
          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
        }, PENDING_AWAIT_TIME_MS);
      }
    }
  }, [invalidateSessionQuery, paymentSessionStatus, screen, timerRef]);

  const handleSelectToken = useCallback((token: TSelectedToken) => {
    log('set token %o', token);
    if (token) {
      handleSetScreen('DepositInfoScreen');
    }

    setSelectedToken(token);
  }, []);

  const ctx = useMemo<TCryptoPaymentContext>(
    () => ({
      screen,
      setScreen,
      selectedToken,
      setSelectedToken: handleSelectToken,
    }),
    [handleSelectToken, screen, selectedToken]
  );

  return (
    <CryptoPaymentContext.Provider value={ctx}>
      {children}
    </CryptoPaymentContext.Provider>
  );
};

export const useCryptoPaymentContext = () => useContext(CryptoPaymentContext);
