import { BadgeStyles } from './badge';
import { ButtonStyles } from './button';
import { BreadcrumbStyles } from './breadcrumb';
import { CheckboxStyles } from './checkbox';
import { RadioStyles } from './radio';
import { SwitchStyles } from './switch';
import { PinStyles } from './pin';
import { InputStyles } from './input';
import { FormLabelStyles } from './formLabel';
import { LinkStyles } from './link';
import { SkeletonStyles } from './skeleton';
import { ModalStyles } from './modal';
import { TableStyles } from './table';
import { PopoverStyles } from './popover';
import { DrawerStyle } from './drawer';
import { TextareaStyles } from './textarea';

export const components = {
  Badge: BadgeStyles,
  Button: ButtonStyles,
  Breadcrumb: BreadcrumbStyles,
  Checkbox: CheckboxStyles,
  Drawer: DrawerStyle,
  Radio: RadioStyles,
  Switch: SwitchStyles,
  PinInput: PinStyles,
  Input: InputStyles,
  NumberInput: InputStyles,
  FormLabel: FormLabelStyles,
  Link: LinkStyles,
  Skeleton: SkeletonStyles,
  Modal: ModalStyles,
  Table: TableStyles,
  Popover: PopoverStyles,
  Textarea: TextareaStyles,
};
