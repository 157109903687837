import { Icon, Tooltip, useClipboard } from '@chakra-ui/react';
import { CopyIcon } from '@payler/ui-icons';
import { useTranslation } from 'react-i18next';

export const CopyComponent = ({ value }: { value: string }) => {
  const { t } = useTranslation();
  const { onCopy, hasCopied } = useClipboard(value ?? '');

  return (
    <Tooltip
      closeOnClick={false}
      hasArrow
      label={hasCopied ? t('common:copied') : t('common:copy')}
      bg="white.500"
      color="primary.400"
    >
      <Icon
        as={CopyIcon}
        w={22}
        h={22}
        color="primary.300"
        cursor="pointer"
        ml={1}
        onClick={(e) => {
          e.stopPropagation();
          onCopy();
        }}
      />
    </Tooltip>
  );
};
