import { useCryptoPaymentContext } from '../context/crypto-payment-context';
import { useIdFromUrl } from '@payler/payment-page-ui-shared';
import { useApi } from '../context/config-context';
import { assert } from '@payler/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useInvalidatePaymentSessionQuery } from './payment-session';

const STALE_TIME = 600_000;

export const useDepositFromSelectedToken = () => {
  const { selectedToken } = useCryptoPaymentContext();
  const sessionId = useIdFromUrl();
  const api = useApi();
  assert(!!sessionId, 'Session id can not be empty or undefined');
  assert(
    !!(selectedToken?.TokenCode && selectedToken.NetworkType),
    'Token must be not empty'
  );

  const { data, isLoading } = useQuery(
    ['token', selectedToken],
    async () => {
      const response = await api.getDepositInfo(sessionId, selectedToken);

      return response.data;
    },
    { staleTime: STALE_TIME, refetchOnWindowFocus: false, retry: false }
  );

  return useMemo(() => ({ data, isLoading }), [data, isLoading]);
};

export const useDepositInfo = () => {
  const { data } = useDepositFromSelectedToken();
  assert(!!data, 'Deposit empty');

  return data;
};

export const useMarkDepositPaidMutation = () => {
  const sessionId = useIdFromUrl();
  const api = useApi();
  const invalidatePaymentSessionQuery = useInvalidatePaymentSessionQuery();

  assert(!!sessionId, 'Session id can not be empty or undefined');

  const { mutate, isLoading } = useMutation(
    ['deposit', sessionId, 'paid'],
    async () => {
      await api.markDepositPaid(sessionId);
    },
    {
      onSuccess: invalidatePaymentSessionQuery,
    }
  );

  return { markDepositPaid: mutate, isLoading };
};
