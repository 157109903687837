import { ScreenWrapper } from '../components/wrappers/screen-wrapper';
import {
  DividedFooter,
  FullPageLoader,
  InputClipboard,
  ScreenBody,
  ScreenHeader,
  useLayout,
} from '@payler/payment-page-ui-shared';
import { Box, Image, VStack, Text, Button, HStack } from '@chakra-ui/react';
import { Alert } from '@payler/payment-page-ui-shared';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TextStyles } from '@payler/ui-theme';
import {
  useDepositFromSelectedToken,
  useDepositInfo,
  useMarkDepositPaidMutation,
} from '../hooks/deposit';
import { useCryptoPaymentContext } from '../context/crypto-payment-context';
import { useAvailableTokens } from '../hooks/tokens';

export const DepositInfoScreen = () => {
  const { data, isLoading } = useDepositFromSelectedToken();

  if (isLoading && !data) {
    return <FullPageLoader />;
  }

  return (
    <ScreenWrapper>
      <Head />
      <Body />
      <Footer />
    </ScreenWrapper>
  );
};

const Head = () => {
  const { addressQrCodeUrl } = useDepositInfo();
  return (
    <Box bg="brands.300">
      <ScreenHeader>
        <Image src={addressQrCodeUrl} w="160px" h="160px" />
      </ScreenHeader>
    </Box>
  );
};

const Body = () => {
  const { tokenCode, address, minimumDeposit, networkType } = useDepositInfo();

  return (
    <ScreenBody>
      <VStack w="full" alignItems="flex-start" spacing={2} mt={3}>
        <Description currency={tokenCode} />
        <DepositInfo address={address.address} network={networkType} />
        <DepositViaAnotherNetworkAlert />
        {minimumDeposit > 0 && (
          <MinimumAmountAlert currency={tokenCode} amount={minimumDeposit} />
        )}
      </VStack>
    </ScreenBody>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  const { isMobile } = useLayout();
  const { markDepositPaid, isLoading } = useMarkDepositPaidMutation();
  const { setScreen } = useCryptoPaymentContext();
  const { data } = useAvailableTokens();

  const isBackButtonAvailable = (data?.length ?? 0) > 1;

  const handleClickMadeDepositButton = () => {
    markDepositPaid();
  };

  const handleClickBack = () => {
    setScreen('PaymentSelectionScreen');
  };

  if (isMobile) {
    return (
      <Box pt={2} pb={2} position="sticky" bottom={0} bg="secondary.500">
        <DividedFooter pb={0}>
          <HStack w="full" spacing={2}>
            {isBackButtonAvailable && (
              <Button w="full" variant="secondary" onClick={handleClickBack}>
                {t('buttons:back')}
              </Button>
            )}
            (
            <Button
              w="full"
              onClick={handleClickMadeDepositButton}
              isLoading={isLoading}
            >
              {t('buttons:madeDeposit')}
            </Button>
            )
          </HStack>
        </DividedFooter>
      </Box>
    );
  }

  return (
    <Box pt={3} pb={4} px={4}>
      <HStack w="full" spacing={2}>
        {isBackButtonAvailable && (
          <Button w="full" variant="secondary" onClick={handleClickBack}>
            {t('buttons:back')}
          </Button>
        )}
        <Button w="full" onClick={handleClickMadeDepositButton}>
          {t('buttons:madeDeposit')}
        </Button>
      </HStack>
    </Box>
  );
};

const Description: FC<{ currency: string }> = ({ currency }) => {
  const { t } = useTranslation('common');
  return (
    <VStack w="full" alignItems="flex-start">
      <Text as="h1" textStyle={TextStyles.h1}>
        {t('depositCurrency', { currency })}
      </Text>
      <Text textStyle={TextStyles.BodyText16Regular} color="primary.350">
        <Trans
          i18nKey="completePayment"
          values={{ currency }}
          components={{
            darker: (
              <Text
                as="span"
                textStyle={TextStyles.BodyText16Medium}
                color="primary.500"
              />
            ),
          }}
        />
      </Text>
    </VStack>
  );
};

const DepositInfo: FC<{ address: string; network: string }> = ({
  address,
  network,
}) => {
  const { t } = useTranslation();
  return (
    <VStack w="full" alignItems="flex-start" spacing={2} mt={1}>
      <Text as="h4" textStyle={TextStyles.h4}>
        {t('common:network')}
      </Text>
      <InputClipboard
        value={network}
        borderRadius="12px"
        minH="56px"
        py={2}
        pl={2}
        fontSize={16}
        fontWeight={400}
        textStyle={TextStyles.BodyText16Regular}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        canCopy={false}
      />
      <Text as="h4" textStyle={TextStyles.h4}>
        {t('common:depositAddress')}
      </Text>
      <InputClipboard
        value={address}
        borderRadius="12px"
        minH="56px"
        py={2}
        pl={2}
        fontSize={16}
        fontWeight={400}
        textStyle={TextStyles.BodyText16Regular}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        copyIconProps={{ py: 0 }}
      />
    </VStack>
  );
};

const MinimumAmountAlert: FC<{ amount: number; currency: string }> = ({
  amount,
  currency,
}) => {
  const { t } = useTranslation('common');
  return (
    <Alert
      label={t('important')}
      description={
        <Trans
          i18nKey="minimumAmountAlert"
          values={{ currency, amount }}
          components={{
            darker: (
              <Text
                as="span"
                textStyle={TextStyles.Subtitle14Medium}
                color="primary.500"
              />
            ),
          }}
        />
      }
    />
  );
};

const DepositViaAnotherNetworkAlert: FC = () => {
  const { t } = useTranslation('common');
  return (
    <Alert
      label={t('important')}
      description={t('depositViaAnotherNetworkAlert')}
    />
  );
};
