import React, { FC, ReactNode } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { Warning12Icon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';

export const Alert: FC<{ label: ReactNode; description: ReactNode }> = ({
  label,
  description,
}) => (
  <Box
    p={2}
    w="full"
    borderRadius={1}
    border="1px solid"
    borderColor="yellow.100"
    backgroundColor="yellow.100"
  >
    <HStack w="full" mb={1} overflow="hidden">
      <Warning12Icon />
      <Text
        textStyle={TextStyles.Tagline10Bold}
        color="primary.500"
        textTransform="uppercase"
      >
        {label}
      </Text>
    </HStack>
    <Text textStyle={TextStyles.Subtitle14Regular} color="primary.500">
      {description}
    </Text>
  </Box>
);
