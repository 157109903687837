import { AxiosError } from 'axios';
import { MayBe } from '@payler/utils';

export class CryptoPaymentNetworkError extends AxiosError {
  private _type: 'expired' | 'other';
  constructor(error: MayBe<AxiosError | Error>) {
    if (error instanceof AxiosError) {
      super(error?.message, error?.code, {}, error?.request, error?.response);
    } else {
      super('CryptoPaymentNetworkError: something got wrong');
    }

    this._type = 'other';
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }
}
