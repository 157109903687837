const translationEn = {
  common: {
    selectCurrency: 'Select currency',
    nothingFound: 'Nothing found',
    depositCurrency: 'Deposit {{currency}}',
    completePayment:
      'Complete the payment by sending <darker>{{currency}}</darker> to this address or scanning the QR code.',
    depositAddress: 'Deposit Address',
    minimumAmountAlert:
      'The minimum deposit amount is <darker>{{amount}}</darker> <darker>{{currency}}</darker>. Deposits below minimum will not be credited.',
    important: 'Important',
    processing: 'Processing',
    depositHasBeenMade: 'Deposit has been made',
    network: 'Network',
    depositViaAnotherNetworkAlert:
      'Deposit via another network will not be credited.',
  },
  placeholders: {
    search: 'Search',
  },
  buttons: {
    madeDeposit: 'I made the deposit',
    backToSite: 'Back to the site',
    back: 'Back',
  },
  fields: {
    created: 'Created',
    receive: 'Receive',
    email: 'Email',
    transactionId: 'Transaction ID',
    transactionHash: 'Transaction Hash',
    inProgress: 'in progress',
  },
  errorPage: {
    reloadPage: 'Reload page',
    timeExpired: 'Time to payment has run out.',
    oops: 'Oops',
    unspecifiedError: 'An unspecified error has occurred',
    somethingWentWrong: 'Something went wrong',
  },
} as const;

export const en = translationEn;
