import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import { TAvailableTokens } from './types/token';
import {
  TDepositInfoRequestParams,
  TDepositInfoResponse,
} from './types/deposit';
import { TResponse } from './types/common';
import { TPaymentSessionResponse } from './types/payment-session';
import { CryptoPaymentNetworkError } from './crypto-payment-network-error';

const paramsSerializer = (data: object): string => {
  return stringify(data, { arrayFormat: 'none' });
};

export class PaymentPageApiCrypto {
  private static getPrepareNetworkError(
    error: AxiosError | Error | null,
    response?: TResponse
  ) {
    if (!(error instanceof AxiosError) && !response) {
      return error;
    }

    const errorInstance = new CryptoPaymentNetworkError(error);

    if (error instanceof AxiosError) {
      const responseData: TResponse = error?.response?.data;
      const errors = responseData.errors;

      if (Array.isArray(errors) && errors[0] === 'Payment form expired') {
        errorInstance.type = 'expired';
      }

      return errorInstance;
    } else if (
      (response?.data as TPaymentSessionResponse['data'])?.paymentForm
        ?.status === 'expired'
    ) {
      errorInstance.type = 'expired';

      return errorInstance;
    }

    return;
  }

  private readonly axios: AxiosInstance;

  constructor(baseURL: string, handleNetworkError: (e: unknown) => void) {
    this.axios = axios.create({
      baseURL,
      paramsSerializer,
    });

    this.axios.interceptors.response.use(
      (response) => {
        /**
         * Этот костыль нужен для следующего:
         * Сервер может кинуть ошибку, а может вернуть ответ со статусом формы expired
         * Поэтому пытаемся отловить статус формы expired и в ответе с 200 статусом
         */
        handleNetworkError(
          PaymentPageApiCrypto.getPrepareNetworkError(null, response?.data)
        );
        return response;
      },
      (error) => {
        handleNetworkError(PaymentPageApiCrypto.getPrepareNetworkError(error));
      }
    );
  }

  async getAvailableTokens(sessionId: string): Promise<TAvailableTokens> {
    const response = await this.axios.get<TAvailableTokens>(
      `/payment-form/${sessionId}/available-tokens`
    );
    return response.data;
  }

  async getDepositInfo(
    sessionId: string,
    params: TDepositInfoRequestParams
  ): Promise<TDepositInfoResponse> {
    const response = await this.axios.get<TDepositInfoResponse>(
      `/payment-form/${sessionId}/deposit-info`,
      { params }
    );

    return response.data;
  }

  async markDepositPaid(sessionId: string): Promise<void> {
    await this.axios.post<TResponse<never>>(`/payment-form/${sessionId}/paid`);
  }

  async getPaymentSession(sessionId: string): Promise<TPaymentSessionResponse> {
    const response = await this.axios.get<TPaymentSessionResponse>(
      `/payment-form/${sessionId}`
    );

    return response.data;
  }
}
