import {
  DesktopWrapper,
  MobileWrapper,
  useLayout,
} from '@payler/payment-page-ui-shared';

export const ScreenWrapper: FCC = ({ children }) => {
  const { isDesktop } = useLayout();

  return isDesktop ? (
    <DesktopWrapper>{children}</DesktopWrapper>
  ) : (
    <MobileWrapper>{children}</MobileWrapper>
  );
};
