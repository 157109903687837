import { useTranslation } from 'react-i18next';
import { Box, Center, Heading, VStack, Text } from '@chakra-ui/react';
import { ScreenWrapper } from '../components/wrappers/screen-wrapper';
import {
  FullPageLoader,
  MethodBlockWrapper,
  MethodButton,
  NothingFoundIcon,
  ScreenBody,
  ScreenHeader,
} from '@payler/payment-page-ui-shared';
import { Input } from '@payler/ui-components';
import { Search2Icon } from '@chakra-ui/icons';
import React, { FC } from 'react';
import { TextStyles } from '@payler/ui-theme';
import { useAvailableTokens, useSearchTokens } from '../hooks/tokens';
import { TToken } from '@payler/payment-page-api-crypto';
import { CryptoCurrencyIcon } from '../components/crypto-currency-icon';
import { useCryptoPaymentContext } from '../context/crypto-payment-context';

export const CurrencySelectionScreen = () => {
  const { isLoading, data } = useAvailableTokens();

  if (isLoading && !data) {
    return <FullPageLoader />;
  }

  return (
    <ScreenWrapper>
      <Head />
      <Content />
    </ScreenWrapper>
  );
};

const Head = () => {
  const { t } = useTranslation();
  return (
    <ScreenHeader>
      <Heading>{t('common:selectCurrency')}</Heading>
    </ScreenHeader>
  );
};

const Content = () => {
  const { searchValue, onSearch, searchedData } = useSearchTokens();

  return (
    <ScreenBody mb={4}>
      <VStack w="full" spacing={2}>
        <SearchField searchValue={searchValue} onSearch={onSearch} />
        <CurrencyList data={searchedData} />
      </VStack>
    </ScreenBody>
  );
};

const SearchField: FC<{
  searchValue: string;
  onSearch: (searchValue: string) => void;
}> = ({ searchValue, onSearch }) => {
  const { t } = useTranslation();

  return (
    <Input
      leftElementProps={{
        children: <Search2Icon w="20px" h="20px" color="primary.400" />,
        pointerEvents: 'none',
        paddingLeft: 2,
      }}
      placeholder={t('placeholders:search')}
      value={searchValue}
      onChange={(event) => onSearch(event.target.value)}
    />
  );
};

const CurrencyList: FC<{ data?: TToken[] }> = ({ data }) => {
  const { setSelectedToken } = useCryptoPaymentContext();
  return (
    <MethodBlockWrapper>
      {data?.length &&
        data.map((token) => (
          <MethodButton
            key={token.name}
            title={<PaymentMethodTitle {...token} />}
            icon={<CryptoCurrencyIcon currency={token.symbol} />}
            onClick={() => {
              setSelectedToken({
                TokenCode: token.symbol,
                NetworkType: token.network,
              });
            }}
          />
        ))}
      {!data?.length && <NotFoundCurrencies />}
    </MethodBlockWrapper>
  );
};

const NotFoundCurrencies = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Center minH="432px">
        <VStack w="full" spacing={3}>
          <NothingFoundIcon />
          <Text textStyle={TextStyles.BodyText16Semibold} color="primary.350">
            {t('common:nothingFound')}
          </Text>
        </VStack>
      </Center>
    </Box>
  );
};

const PaymentMethodTitle: FC<TToken> = ({ symbol, name, network, address }) => {
  return (
    <VStack w="full" gap={0}>
      <Text
        w="full"
        textAlign="start"
        textStyle={TextStyles.BodyText16Medium}
        whiteSpace="nowrap"
        maxWidth="full"
        overflow="hidden"
        textOverflow="ellipsis"
        alignItems="center"
      >
        {symbol}
      </Text>
      <Text
        w="full"
        textStyle={TextStyles.Caption12Regular}
        color="primary.350"
        textAlign="start"
      >
        {name && <>{name}&#183;</>}
        {network}
      </Text>
    </VStack>
  );
};
