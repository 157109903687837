import {
  DividedFooter,
  InputClipboard,
  ScreenBody,
  ScreenHeader,
  SuccessIcon,
  useLayout,
} from '@payler/payment-page-ui-shared';
import { ScreenWrapper } from '../components/wrappers/screen-wrapper';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { useTransactionData } from '../hooks/transaction';
import { ThinDivider } from '@payler/ui-components';
import dayjs from 'dayjs';
import { useSiteUrl } from '../hooks/payment-session';

export const PaymentResultScreen = () => {
  return (
    <ScreenWrapper>
      <Head />
      <Content />
      <Footer />
    </ScreenWrapper>
  );
};

const Head = () => {
  const { t } = useTranslation('common');
  return (
    <ScreenHeader>
      <HStack spacing={2}>
        <Icon as={SuccessIcon} color="green.500" w={4} h={4} />
        <Text as={'h1'} textStyle={TextStyles.h1}>
          {t('depositHasBeenMade')}
        </Text>
      </HStack>
    </ScreenHeader>
  );
};

const Content = () => {
  const { t } = useTranslation('fields');
  const {
    created,
    tokenCode,
    userEmail,
    amount,
    transactionId,
    transactionHash,
  } = useTransactionData();

  return (
    <ScreenBody mb={3}>
      <VStack
        spacing={0}
        divider={<ThinDivider borderColor="primary.25" />}
        w="full"
        border="1px solid"
        borderRadius={1}
        borderColor="primary.100"
      >
        <InputClipboard
          label={t('created')}
          value={dayjs(created).format('DD.MM.YYYY, HH:mm:ss')}
          canCopy={false}
        />
        <InputClipboard
          label={t('receive')}
          value={`+ ${amount} ${tokenCode}`}
          canCopy={false}
        />
        <InputClipboard
          label={t('transactionId')}
          value={transactionId || '-'}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        />
        <InputClipboard
          label={t('transactionHash')}
          value={transactionHash || '-'}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        />
        <InputClipboard
          label={t('email')}
          value={userEmail || ''}
          canCopy={false}
        />
      </VStack>
    </ScreenBody>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  const { isMobile } = useLayout();
  const url = useSiteUrl();

  if (!url) {
    return null;
  }

  const handleClickBackToSite = () => {
    window.location.replace(url);
  };

  if (isMobile) {
    return (
      <Box pt={2} pb={3} position="sticky" bottom={0} bg="secondary.500">
        <DividedFooter>
          <Button w="full" onClick={handleClickBackToSite}>
            {t('buttons:backToSite')}
          </Button>
        </DividedFooter>
      </Box>
    );
  }

  return (
    <Box pt={2} pb={4} px={4}>
      <VStack w="full">
        <Button w="full" onClick={handleClickBackToSite}>
          {t('buttons:backToSite')}
        </Button>
      </VStack>
    </Box>
  );
};
